var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"570"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"height":"30px","magin":"5px 0"},attrs:{"elevation":"0","block":""}},on),[_vm._v(" "+_vm._s(_vm.$t("accounts.configuration"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-decoration-underline text-uppercase text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$t("tattooers.reservation_system"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"7"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.reservation_system_complete"))+" ")]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"5"}},[_c('LaborExange',{attrs:{"setting_key":"labor_exange"}}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 70px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                  _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,3263129587)},[_c('span',{staticStyle:{"font-size":"12px"}},[_c('p',[_vm._v("Cuando el cliente SELECCIONA un artista del estudio")]),_c('ul',[_c('li',[_c('b',[_vm._v("Día y hora:")]),_vm._v(" El cliente puede seleccionar el día y la hora disponible ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo semanal:")]),_vm._v(" El cliente puede seleccionar una semana. El estudio/artista será el encargado de proponer día y hora. ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo mensual:")]),_vm._v(" El cliente puede seleccionar un mes. El estudio/artista será el encargado de proponer día y hora. ")])])])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 130px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                  _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,3541503238)},[_c('span',{staticClass:"py-0 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_c('p',[_vm._v("Cuando el cliente SELECCIONA un artista del estudio")]),_c('ul',[_c('li',[_c('b',[_vm._v("Día y hora:")]),_vm._v(" El cliente puede seleccionar el día y la hora disponible ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo semanal:")]),_vm._v(" El cliente puede seleccionar una semana. El estudio/artista será el encargado de proponer día y hora. ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo mensual:")]),_vm._v(" El cliente puede seleccionar un mes. El estudio/artista será el encargado de proponer día y hora. ")])])])]):_vm._e()],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"7"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.reservation_system_tattooer"))+" ")]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"5"}},[_c('LaborExange',{attrs:{"setting_key":"labor_exange_tattooer"}}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 125px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                  _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2601205026)},[_c('span',{staticStyle:{"font-size":"12px"}},[_c('p',[_vm._v(" Cuando el cliente NO SABE el artista. El estudio deberá asignar el artista al estudio. ")]),_c('ul',[_c('li',[_c('b',[_vm._v("Día y hora:")]),_vm._v(" El cliente puede seleccionar el día y la hora disponible ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo semanal:")]),_vm._v(" El cliente puede seleccionar una semana. El estudio/artista será el encargado de proponer día y hora. ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo mensual:")]),_vm._v(" El cliente puede seleccionar un mes. El estudio/artista será el encargado de proponer día y hora. ")])])])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 235px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                  _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,1874062528)},[_c('span',{staticClass:"py-0 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_c('p',[_vm._v(" Cuando el cliente NO SABE el artista. El estudio deberá asignar el artista al estudio. ")]),_c('ul',[_c('li',[_c('b',[_vm._v("Día y hora:")]),_vm._v(" El cliente puede seleccionar el día y la hora disponible ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo semanal:")]),_vm._v(" El cliente puede seleccionar una semana. El estudio/artista será el encargado de proponer día y hora. ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo mensual:")]),_vm._v(" El cliente puede seleccionar un mes. El estudio/artista será el encargado de proponer día y hora. ")])])])]):_vm._e()],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"9"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.reservation_system_help_ask"))+" ")]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.items,"item-text":"text","item-value":"value","outlined":"","dense":"","hide-details":"","disabled":_vm.disabled},model:{value:(_vm.assistance),callback:function ($$v) {_vm.assistance=$$v},expression:"assistance"}}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 185px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                  _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,1653789672)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Mediante el formulario de reserva del cliente, este puede solicitar asesoramiento para que el estudio lo gestione. ")])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 360px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                  _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,3635052897)},[_c('span',{staticClass:"py-0 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_vm._v(" Mediante el formulario de reserva del cliente, este puede solicitar asesoramiento para que el estudio lo gestione. ")])]):_vm._e()],1),(_vm.assistance === 1)?[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"7"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.reservation_system_help"))+" ")]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"5"}},[_c('LaborExange',{attrs:{"setting_key":"labor_exange_help"}}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 240px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                    _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,4196885986)},[_c('span',{staticStyle:{"font-size":"12px"}},[_c('p',[_vm._v(" Cuando el cliente solicita una cita para que el estudio lo asesore ")]),_c('ul',[_c('li',[_c('b',[_vm._v("Día y hora:")]),_vm._v(" El cliente puede seleccionar el día y la hora disponible ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo semanal:")]),_vm._v(" El cliente puede seleccionar una semana. El estudio/artista será el encargado de proponer día y hora. ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo mensual:")]),_vm._v(" El cliente puede seleccionar un mes. El estudio/artista será el encargado de proponer día y hora. ")])])])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({style:('position: absolute; right: 8px; top: 490px'),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                    _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,1754622089)},[_c('span',{staticClass:"py-0 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_c('p',[_vm._v(" Cuando el cliente solicita una cita para que el estudio lo asesore ")]),_c('ul',[_c('li',[_c('b',[_vm._v("Día y hora:")]),_vm._v(" El cliente puede seleccionar el día y la hora disponible ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo semanal:")]),_vm._v(" El cliente puede seleccionar una semana. El estudio/artista será el encargado de proponer día y hora. ")]),_c('li',[_c('b',[_vm._v("Bolsa de trabajo mensual:")]),_vm._v(" El cliente puede seleccionar un mes. El estudio/artista será el encargado de proponer día y hora. ")])])])]):_vm._e()],1)]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }