<template>
  <v-dialog width="570" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn style="height: 30px; magin: 5px 0" elevation="0" block v-on="on">
        {{ $t("accounts.configuration") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="text-decoration-underline text-uppercase text-no-wrap"
      >
        {{ $t("tattooers.reservation_system") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col class="py-1" cols="12" md="7">
            {{ $t("tattooers.reservation_system_complete") }}
          </v-col>
          <v-col class="py-1" cols="12" md="5">
            <LaborExange setting_key="labor_exange"></LaborExange>
            <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 70px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span style="font-size: 12px">
                <p>Cuando el cliente SELECCIONA un artista del estudio</p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-tooltip>
            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 130px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span class="py-0 mb-0 px-5" style="font-size: 12px">
                <p>Cuando el cliente SELECCIONA un artista del estudio</p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-menu>
          </v-col>

          <v-col class="py-1" cols="12" md="7">
            {{ $t("tattooers.reservation_system_tattooer") }}
          </v-col>
          <v-col class="py-1" cols="12" md="5">
            <LaborExange setting_key="labor_exange_tattooer"></LaborExange>
            <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 125px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span style="font-size: 12px">
                <p>
                  Cuando el cliente NO SABE el artista. El estudio deberá
                  asignar el artista al estudio.
                </p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-tooltip>
            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 235px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span class="py-0 mb-0 px-5" style="font-size: 12px">
                <p>
                  Cuando el cliente NO SABE el artista. El estudio deberá
                  asignar el artista al estudio.
                </p>
                <ul>
                  <li>
                    <b>Día y hora:</b> El cliente puede seleccionar el día y la
                    hora disponible
                  </li>
                  <li>
                    <b>Bolsa de trabajo semanal:</b> El cliente puede
                    seleccionar una semana. El estudio/artista será el encargado
                    de proponer día y hora.
                  </li>
                  <li>
                    <b>Bolsa de trabajo mensual:</b> El cliente puede
                    seleccionar un mes. El estudio/artista será el encargado de
                    proponer día y hora.
                  </li>
                </ul>
              </span>
            </v-menu>
          </v-col>
          <v-col class="py-1" cols="12" md="9">
            {{ $t("tattooers.reservation_system_help_ask") }}
          </v-col>
          <v-col class="py-1" cols="12" md="3">
            <v-select
              :items="items"
              item-text="text"
              item-value="value"
              v-model="assistance"
              outlined
              dense
              hide-details
              :disabled="disabled"
            ></v-select>
            <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 185px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span style="font-size: 12px">
                Mediante el formulario de reserva del cliente, este puede
                solicitar asesoramiento para que el estudio lo gestione.
              </span>
            </v-tooltip>
            <v-menu
              v-if="$vuetify.breakpoint.smAndDown"
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="'position: absolute; right: 8px; top: 360px'"
                  color="red"
                  @click="
                    $emit('changeTab');
                    $refs.card.dialog = false;
                  "
                  >mdi-information-outline</v-icon
                >
              </template>
              <span class="py-0 mb-0 px-5" style="font-size: 12px">
                Mediante el formulario de reserva del cliente, este puede
                solicitar asesoramiento para que el estudio lo gestione.
              </span>
            </v-menu>
          </v-col>
          <template v-if="assistance === 1">
            <v-col class="py-1" cols="12" md="7">
              {{ $t("tattooers.reservation_system_help") }}
            </v-col>
            <v-col class="py-1" cols="12" md="5">
              <LaborExange setting_key="labor_exange_help"></LaborExange>
              <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :style="'position: absolute; right: 8px; top: 240px'"
                    color="red"
                    @click="
                      $emit('changeTab');
                      $refs.card.dialog = false;
                    "
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span style="font-size: 12px">
                  <p>
                    Cuando el cliente solicita una cita para que el estudio lo
                    asesore
                  </p>
                  <ul>
                    <li>
                      <b>Día y hora:</b> El cliente puede seleccionar el día y
                      la hora disponible
                    </li>
                    <li>
                      <b>Bolsa de trabajo semanal:</b> El cliente puede
                      seleccionar una semana. El estudio/artista será el
                      encargado de proponer día y hora.
                    </li>
                    <li>
                      <b>Bolsa de trabajo mensual:</b> El cliente puede
                      seleccionar un mes. El estudio/artista será el encargado
                      de proponer día y hora.
                    </li>
                  </ul>
                </span>
              </v-tooltip>
              <v-menu
                v-if="$vuetify.breakpoint.smAndDown"
                :close-on-content-click="true"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :style="'position: absolute; right: 8px; top: 490px'"
                    color="red"
                    @click="
                      $emit('changeTab');
                      $refs.card.dialog = false;
                    "
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span class="py-0 mb-0 px-5" style="font-size: 12px">
                  <p>
                    Cuando el cliente solicita una cita para que el estudio lo
                    asesore
                  </p>
                  <ul>
                    <li>
                      <b>Día y hora:</b> El cliente puede seleccionar el día y
                      la hora disponible
                    </li>
                    <li>
                      <b>Bolsa de trabajo semanal:</b> El cliente puede
                      seleccionar una semana. El estudio/artista será el
                      encargado de proponer día y hora.
                    </li>
                    <li>
                      <b>Bolsa de trabajo mensual:</b> El cliente puede
                      seleccionar un mes. El estudio/artista será el encargado
                      de proponer día y hora.
                    </li>
                  </ul>
                </span>
              </v-menu>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ReservationSystem",
  data() {
    return {
      dialog: false,
      items: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
    };
  },
  components: {
    LaborExange: () => import("@/components/user/settings/profile/LaborExange"),
  },
  methods: {
    ...mapActions("users", ["settingUpdate"]),
  },
  computed: {
    assistance: {
      get() {
        return this.$store.getters["auth/getSetting"]("assistance_appointment");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        if (value === 0) {
          this.$confirm(this.$t("desactivate_assistance")).then(() => {
            this.settingUpdate({ key: "assistance_appointment", value }).then(
              (data) => {
                this.$alert(this.$t("desactivated"));
              }
            );
          });
          return;
        }
        this.settingUpdate({ key: "assistance_appointment", value }).then(
          (data) => {
            this.$alert(this.$t("activated"));
          }
        );
      },
    },
  },
};
</script>
